import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";
import { getIsOnpremise } from "../../../utils";

const useLogoutService = () => {
  const isOnPremise = getIsOnpremise();
  return useMutation(() => {
    if (isOnPremise) {
      return servicesUtils.getService("/accounts/local/logout/");
    } else {
      return servicesUtils.deleteService("/_allauth/browser/v1/auth/session");
    }
  });
};

export default useLogoutService;
