import { useQuery } from "react-query";
import axiosConnect from "../../../utils/axiosConnect";
import { get } from "lodash";
var useGetCsrf = function useGetCsrf(isOpen) {
  return useQuery(["csrf", isOpen], function () {
    return axiosConnect.get("/set_csrf/");
  }, {
    select: function select(response) {
      return get(response, ["headers", "x-csrftoken"], "");
    },
    enabled: isOpen
  });
};
export default useGetCsrf;